import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  Tag,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  GlobalOutlined,
  EditTwoTone,
  DeleteTwoTone,
  PartitionOutlined,
} from "@ant-design/icons";
import {
  createCity,
  getCities,
  updateCity,
  deleteCity,
  getRegion,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Cities = () => {
  const ADD_CITY = "Add City";
  const [modalTitle, setModalTitle] = useState([ADD_CITY]);
  const [modalVisible, setModalVisible] = useState();
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [citiesTableData, setCitiesTableData] = useState([]);
  const [region, setRegion] = useState([]);
  const [cityForm] = Form.useForm();
  const { t } = useTranslation();
  let { regionId } = useParams();

  useEffect(() => {
    getRegion(regionId)
      .then((response) => {
        setRegion(response.data.data.region);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [regionId]);

  useEffect(() => {
    setIsDataLoading(true);
    getCities(regionId)
      .then((response) => {
        setCitiesTableData(response.data.data.cities);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData, regionId]);

  const showAddModal = () => {
    // set Modal Title
    setModalTitle(t("city_add"));

    // Reset Form Field
    cityForm.resetFields();

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    cityForm.setFieldsValue({
      is_active: true,
      region_id: regionId,
    });
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_CITY) {
        NotificationWithIcon(
          "success",
          t("city_added"),
          t("city_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("city_updated"),
          t("city_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    cityForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditCity = () => {
    cityForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_CITY || modalTitle === t("city_add")) {
          createCity(regionId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, ADD_CITY);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateCity(regionId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (city_id) => {
    // Find Cities from DataArray
    const city = citiesTableData.find((emp) => emp.id === city_id);

    // Set Modal Title
    setModalTitle(t("city_edit") + " : " + city.name_en);

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    cityForm.setFieldsValue({
      id: city.id,
      name_en: city.name_en,
      name_ar: city.name_ar,
      region_id: city.region.id,
      is_active: city.is_active,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    cityForm.resetFields();
    setModalVisible(false);
  };

  const handleDeleteCities = (id) => {
    deleteCity(regionId, id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadTableData(!reloadTableData);
        // Success
        NotificationWithIcon(
          "success",
          t("city_deleted"),
          t("city_deleted_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "regions",
      breadcrumbName: t("menu_regions"),
      icon: <GlobalOutlined />,
    },
    {
      path: "cities",
      breadcrumbName: t("menu_cities"),
      icon: <PartitionOutlined />,
    },
  ];

  const CitiesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name_en"),
      dataIndex: "name_en",
    },
    {
      title: t("name_ar"),
      dataIndex: "name_ar",
    },
    {
      title: t("col_region"),
      dataIndex: "region",
      render: (region) => {
        return region.name_en;
      },
    },
    {
      title: t("col_status"),
      dataIndex: "is_active",
      render: (is_active) => {
        return is_active ? (
          <Tag color="green">{t("active")}</Tag>
        ) : (
          <Tag color="red">{t("inactive")}</Tag>
        );
      },
    },
    {
      title: t("col_action"),
      width: "10%",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("city_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDeleteCities(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_cities")}
          ghost={false}
          extra={[
            <Button
              key="open-add-city-modal"
              type="primary"
              onClick={showAddModal}
            >
              + {t("city_add")}
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={CitiesTableColumns}
          dataSource={citiesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEditCity}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditCity}
          >
            {modalTitle === ADD_CITY || modalTitle === t("city_add")
              ? t("city_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-city-form"
          layout="vertical"
          onFinish={handleAddEditCity}
          style={{ width: "100%", margin: "0" }}
          form={cityForm}
        >
          <Form.Item
            name="name_en"
            label={t("name_en")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
              {
                min: 3,
                message: t("validate_name_min_characters"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="name_ar"
            label={t("name_ar")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
              {
                min: 3,
                message: t("validate_name_min_characters"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item name="region_name" label={t("col_region")} hasFeedback>
            <Input disabled placeholder={region.name_en} />
          </Form.Item>

          <Form.Item
            name="is_active"
            label={t("status")}
            rules={[
              {
                required: true,
                message: t("validate_status_required"),
              },
            ]}
          >
            <Radio.Group
              defaultValue={true}
              value={true}
              buttonStyle="solid"
              key="is-active"
            >
              <Radio.Button key="is-active-false" value={false}>
                {t("inactive")}
              </Radio.Button>
              <Radio.Button key="is-active-true" value={true}>
                {t("active")}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="region_id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Cities;
