import React, { useEffect, useState } from "react";
import { Card, Form, Button, Table, Modal, InputNumber } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  EditTwoTone,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import {
  showSubscriptionPlans,
  updateSubscriptionPlans,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import { PLAN_TYPE_DAILY } from "../../../configs/constants";

const SubscriptionPlans = () => {
  const [modalTitle, setModalTitle] = useState("");
  const [planType, setPlanType] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [formData] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    showSubscriptionPlans()
      .then((response) => {
        setPlanData(response.data.data.subscription_plans);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const handleEdit = () => {
    formData
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        updateSubscriptionPlans(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              setReloadTableData(!reloadTableData);
              // Success
              NotificationWithIcon(
                "success",
                t("subscription_updated"),
                t("subscription_updated_successfully")
              );
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
            setModalVisible(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (plan) => {
    setPlanType(plan.type);
    // Set Modal Title
    setModalTitle(t("menu_subscription_plans") + " : " + plan.name);
    // Set Form Fields
    formData.setFieldsValue({
      id: plan.id,
      // price: 0,
      // discount: 0,
      user_price: plan.user_price,
      user_discount: plan.user_discount,
    });
    // Show Modal
    setModalVisible(true);
  };

  const handleCancel = () => {
    // Reset Form Fields
    formData.resetFields();
    setModalVisible(false);
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "subscription-plans",
      breadcrumbName: t("menu_subscription_plans"),
      icon: <DeploymentUnitOutlined />,
    },
  ];

  const tableColumnsData = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    // {
    //   title: t("price"),
    //   dataIndex: "price",
    // },
    // {
    //   title: t("discount"),
    //   dataIndex: "discount",
    // },
    {
      title: t("user_price"),
      dataIndex: "user_price",
    },
    {
      title: t("user_discount"),
      dataIndex: "user_discount",
    },
    {
      title: t("validity_days"),
      dataIndex: "validity_days",
    },
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Button type="link" onClick={() => handleEditModal(record)}>
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_subscription_plans")}
          ghost={false}
          extra={[]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumnsData}
          dataSource={planData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleEdit}
          >
            {t("update")}
          </Button>,
        ]}
      >
        <Form
          name="edit-subscription-plan-form"
          layout="vertical"
          onFinish={handleEdit}
          style={{ width: "100%", margin: "0" }}
          form={formData}
        >
          {/* <Form.Item
            name="price"
            label={t("price")}
            rules={[
              {
                required: true,
                message: t("validate_price_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="discount"
            label={t("discount") + (planType === PLAN_TYPE_DAILY ? " (%)" : "")}
            rules={[
              {
                required: true,
                message: t("validate_discount_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item> */}
          <Form.Item
            name="user_price"
            label={t("user_price")}
            rules={[
              {
                required: true,
                message: t("validate_price_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="user_discount"
            label={
              t("user_discount") + (planType === PLAN_TYPE_DAILY ? " (%)" : "")
            }
            rules={[
              {
                required: true,
                message: t("validate_discount_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SubscriptionPlans;
