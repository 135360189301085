import "./App.less";
import { useEffect, useContext } from "react";
import { ConfigProvider, Spin } from "antd";
import ar_EG from "antd/lib/locale/ar_EG";
import en_GB from "antd/lib/locale/en_GB";
import Login from "../Auth/Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import { AppContext } from "../../context/AppContextProvider";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getMyProfile } from "../../network/network";
import { useTranslation } from "react-i18next";

const App = (props) => {
  const {
    authUser,
    setAuthUser,
    fullPageLoading,
    setFullPageLoading,
    setActiveMenu,
    appConfig,
  } = useContext(AppContext);
  let navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    var access_token = localStorage.getItem("access_token");
    if (access_token) {
      setFullPageLoading(true);
      getMyProfile()
        .then((response) => {
          setAuthUser(response.data.data.user);
          // Redirect to last visited page
          if (window.location.pathname !== "/") {
            if (localStorage.getItem("last_visited_page")) {
              const lastVisitedPage = JSON.parse(
                localStorage.getItem("last_visited_page")
              );
              if (lastVisitedPage.key && lastVisitedPage.path) {
                navigate(lastVisitedPage.path);
                setActiveMenu(lastVisitedPage.key);
              } else {
                navigate("/home");
              }
            } else {
              navigate("/home");
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setFullPageLoading(false);
        });
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAuthUser, setFullPageLoading, setActiveMenu]);

  useEffect(() => {
    if (appConfig.direction === "rtl") {
      i18n.changeLanguage("ar-EG");
    } else {
      i18n.changeLanguage("en-GB");
    }
  }, [appConfig, i18n]);

  return (
    <ConfigProvider
      direction={appConfig.direction}
      locale={appConfig.direction === "ltr" ? en_GB : ar_EG}
    >
      {fullPageLoading && (
        <div
          style={{
            textAlign: "center",
            paddingTop: "45vh",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            background: "#ffffff",
            zIndex: "999999",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {!fullPageLoading && !authUser ? (
        <Routes>
          <Route
            exact
            path="/login"
            name="Login"
            element={<Login />}
            key="login"
          />
        </Routes>
      ) : (
        <Dashboard />
      )}
    </ConfigProvider>
  );
};

export default App;
