import "./Dashboard.css";
import React, { useEffect, useState, useContext } from "react";
import { Menu, Layout, Space, Button, Dropdown } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import AppRoutes, { ContentPages } from "../../routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getNotifications,
  updateAuthDetails,
  userLogout,
} from "../../network/network";
import { filterPagesByAddons } from "../../utils";
import NotificationsDropdown from "../../components/NotificationsDropdown";
import { SIDEBAR_MENU_KEY_HOME } from "../../configs/constants";
import { useNavigate } from "react-router-dom";

const { Header, Sider, Content, Footer } = Layout;

const Dashboard = () => {
  const {
    authUser,
    activeMenu,
    setActiveMenu,
    appConfig,
    setAppConfigWrapper,
  } = useContext(AppContext);
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const [unReadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    setAppConfigWrapper(authUser.app_config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authUser.business_name,
    authUser.business_type,
    authUser.cr,
    authUser.vat,
    authUser.address,
    authUser.logo,
    authUser.app_config,
  ]);

  useEffect(() => {
    if (
      authUser &&
      Object.keys(appConfig).length > 0 &&
      JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
    ) {
      const formData = new FormData();
      formData.append("app_config", JSON.stringify(appConfig));
      updateAuthDetails(formData).then((res) => {});
    }
  }, [appConfig, authUser]);

  useEffect(() => {
    if (authUser !== null) {
      const privateChannel = window.Echo.private(
        "App.Models.User." + authUser.id
      );

      const handleNotification = (notification) => {
        setUnreadNotificationsCount((count) => count + 1);
        setNotifications((notifs) => [notification, ...notifs]);
      };

      privateChannel.notification(handleNotification);

      return () => {
        privateChannel.stopListening(".notification");
      };
    }
  }, [authUser]);

  useEffect(() => {
    setUnreadNotificationsCount(authUser.unread_notifications_count);
    setNotifications(authUser.notifications?.notifications ?? []);
  }, [authUser]);

  const changeUnreadNotificationsCount = (value) => {
    setUnreadNotificationsCount((prevCount) => value);
  };

  const handleLoadMore = (page) => {
    getNotifications(page)
      .then((response) => {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...response.data?.data?.notifications,
        ]);
      })
      .catch((info) => {
        // Error
        console.log(
          "Getting more notifications error: ",
          info.response.message
        );
      });
  };

  const saveCurrentPageView = (key) => {
    setActiveMenu(key);
    localStorage.setItem(
      "last_visited_page",
      JSON.stringify({
        key: key,
        path: window.location.pathname,
      })
    );
  };

  const logoutUser = () => {
    userLogout().then(function () {
      localStorage.removeItem("last_visited_page");
      localStorage.removeItem("access_token");
      window.location = "/login";
    });
  };

  const UserOptions = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            logoutUser();
          }}
        >
          {t("logout")}
        </div>
      ),
    },
  ];

  const NotificationOptions = [
    {
      key: "1",
      label: (
        <NotificationsDropdown
          notifications={notifications}
          unReadNotificationsCount={unReadNotificationsCount}
          changeUnreadNotificationsCount={changeUnreadNotificationsCount}
          onLoadMore={handleLoadMore}
        />
      ),
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          backgroundColor: "#ffffff",
          position: "sticky",
          top: 0,
          zIndex: 100,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          lineHeight: "unset",
          padding: "0 20px",
          boxShadow: "#0000005c 0px 0px 30px -10px",
          maxHeight: "65px",
          height: "100vh",
        }}
      >
        <Space size={20} style={{ flexGrow: "1", justifyContent: "start" }}>
          <Button
            onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
            shape="round"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0",
              height: "unset",
            }}
          >
            {appConfig.direction === "ltr" ? (
              isSiderCollapsed ? (
                <MenuUnfoldOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{
                    fontSize: "20px",
                    display: "inline-flex",
                    backgroundColor: "#f4e6ff",
                    padding: "4px",
                    borderRadius: "35rem",
                    margin: "2px",
                  }}
                />
              )
            ) : isSiderCollapsed ? (
              <MenuFoldOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
            )}
          </Button>
          <img
            src="/logo.png"
            alt="AnyPOS Logo"
            style={{
              width: "100%",
              maxWidth: "60px",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/home");
              setActiveMenu(SIDEBAR_MENU_KEY_HOME);
            }}
          />
        </Space>
        <Space size={20} style={{ flexGrow: "1", justifyContent: "end" }}>
          <Dropdown menu={{ items: NotificationOptions }} trigger={["click"]}>
            <Button
              shape="round"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0",
                height: "unset",
              }}
            >
              <BellOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
            </Button>
          </Dropdown>
          <Dropdown menu={{ items: UserOptions }} trigger={["click"]}>
            <Button
              shape="round"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0",
                height: "unset",
              }}
            >
              <UserOutlined
                style={{
                  fontSize: "20px",
                  display: "inline-flex",
                  backgroundColor: "#f4e6ff",
                  padding: "4px",
                  borderRadius: "35rem",
                  margin: "2px",
                }}
              />
              {authUser.name + " "}
              <span style={{ margin: "0 8px" }}>
                <DownOutlined
                  style={{
                    fontSize: "12px",
                  }}
                />
              </span>
            </Button>
          </Dropdown>
        </Space>
      </Header>
      <Layout style={{ position: "relative" }}>
        <Sider
          collapsible
          theme="light"
          trigger={null}
          defaultCollapsed={false}
          collapsed={isSiderCollapsed}
          collapsedWidth={150}
          width={220}
          style={{
            boxShadow: "#0000005c 0px 0px 30px -10px",
            overflow: "auto",
            position: "fixed",
            left: appConfig.direction === "ltr" ? 0 : "unset",
            right: appConfig.direction === "ltr" ? "unset" : 0,
            top: 65,
            bottom: 0,
          }}
        >
          <Menu
            mode="vertical"
            className={isSiderCollapsed ? "custom-menu" : ""}
            selectedKeys={[activeMenu]}
            items={filterPagesByAddons(ContentPages, authUser)
              .map((page) => {
                if (page.showInSider) {
                  if (page.subMenus && page.subMenus.length > 0) {
                    return {
                      key: page.name,
                      icon: React.createElement(page.icon),
                      label: t(page.text),
                      children: filterPagesByAddons(page.subMenus, authUser)
                        .map((subPage) => {
                          if (subPage.showInSider) {
                            return {
                              key: subPage.name,
                              icon: React.createElement(subPage.icon),
                              label: (
                                <Link to={subPage.path}>{t(subPage.text)}</Link>
                              ),
                              onClick: () => saveCurrentPageView(subPage.name),
                              className: "custom-menu-popup",
                            };
                          }
                          return null;
                        })
                        .filter(Boolean),
                    };
                  } else {
                    return {
                      key: page.name,
                      icon: React.createElement(page.icon),
                      label: <Link to={page.path}>{t(page.text)}</Link>,
                      onClick: () => saveCurrentPageView(page.name),
                    };
                  }
                }
                return null;
              })
              .filter(Boolean)}
          />
        </Sider>

        <Layout
          style={{
            marginLeft:
              appConfig.direction === "ltr"
                ? isSiderCollapsed
                  ? 150
                  : 220
                : 0,
            marginRight:
              appConfig.direction === "ltr" ? 0 : isSiderCollapsed ? 150 : 220,
          }}
        >
          <Content
            style={{
              margin: "20px",
              padding: "0",
              background: "transparent",
              minHeight: "calc(100vh - 105px)",
            }}
          >
            <AppRoutes />
          </Content>
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#ffffff",
              fontWeight: "500",
              padding: "10px",
            }}
          >
            AnyPOS © {new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
