import "./Helpdesk.css";
import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, CustomerServiceOutlined } from "@ant-design/icons";
import { getTicketsCount } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import NewTickets from "../Tickets/NewTickets";
import InprogressTickets from "../Tickets/InprogressTickets";
import DoneTickets from "../Tickets/DoneTickets";
import ClosedTickets from "../Tickets/ClosedTickets";
import LateTickets from "../Tickets/LateTickets";
import DelayedTickets from "../Tickets/DelayedTickets";

const Helpdesk = () => {
  const [reload, setReload] = useState(false);
  const [ticketsCount, setTicketsCount] = useState([]);
  const [tickets, setTickets] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    getTicketsCount().then((response) => {
      setTicketsCount(response.data.data);
    });
  }, [reload]);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const urlStatus = query.get("status");
    if (urlStatus) {
      setTickets(urlStatus);
    }
  }, [search]);

  const onChangeTab = (key) => {
    setTickets(key);
    const params = new URLSearchParams();
    params.append("status", key);
    params.append("page", 1);
    // Get Selected Tab
    navigate({
      pathname: "/helpdesk",
      search: "?" + params.toString(),
    });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "helpdesk",
      breadcrumbName: t("menu_help_desk"),
      icon: <CustomerServiceOutlined />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_help_desk")}
          ghost={false}
        />
      </Card>
      <Row
        gutter={[20, 20]}
        style={{ marginBottom: "20px", textAlign: "center" }}
      >
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "new_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("new_tickets")}
          >
            <div>
              {t("new_tickets")}
              <span className="tab_count">
                {ticketsCount.new_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "inprogress_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("inprogress_tickets")}
          >
            <div>
              {t("inprogress_tickets")}
              <span className="tab_count">
                {ticketsCount.in_progress_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "done_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("done_tickets")}
          >
            <div>
              {t("done_tickets")}
              <span className="tab_count">
                {ticketsCount.done_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "closed_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("closed_tickets")}
          >
            <div>
              {t("closed_tickets")}{" "}
              <span className="tab_count">
                {ticketsCount.closed_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
      </Row>

      {tickets === "new_tickets" && (
        <NewTickets reloadCallback={() => setReload(!reload)} />
      )}
      {tickets === "inprogress_tickets" && (
        <InprogressTickets reloadCallback={() => setReload(!reload)} />
      )}
      {tickets === "done_tickets" && (
        <DoneTickets reloadCallback={() => setReload(!reload)} />
      )}
      {tickets === "closed_tickets" && (
        <ClosedTickets reloadCallback={() => setReload(!reload)} />
      )}
      {tickets === "late_tickets" && (
        <LateTickets reloadCallback={() => setReload(!reload)} />
      )}
      {tickets === "delayed_tickets" && (
        <DelayedTickets reloadCallback={() => setReload(!reload)} />
      )}
    </>
  );
};

export default Helpdesk;
