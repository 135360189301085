import { doGet, doPost, doDelete } from "./config";

// console.log(localStorage.getItem("access_token"));

// -------------------- Auth API's-----------------------------------
export const userLogin = (data) => {
  return doPost("/login", data, "");
};

export const userLogout = (data) => {
  return doPost("/logout", data, localStorage.getItem("access_token"));
};

export const userRegister = (data) => {
  return doPost("/register", data, "");
};

export const userRegisterValidate = (data) => {
  return doPost("/register/validate", data, "");
};

export const getMyProfile = () => {
  return doGet("/is-login", localStorage.getItem("access_token"));
};

export const updateAuthDetails = (data) => {
  return doPost(`/users/update`, data, localStorage.getItem("access_token"));
};

// export const getEmailFromToken = (resetToken) => {
//   return doGet(`/reset/${resetToken}`, "");
// };

// export const updatePassword = (data) => {
//   return doPatch("/resetPassword", data, "");
// };

export const sendOTPSMS = (data) => {
  return doPost(`/send-otp-sms`, data, "");
};
// =====================Auth API's end===============================

// -------------------- Home API's ----------------------------------
export const getHomeDataSummary = () => {
  return doGet(
    "/reports/home-data-summary",
    localStorage.getItem("access_token"),
    {}
  );
};
export const getDashboardSummary = () => {
  return doGet("/admin/dashboard", localStorage.getItem("access_token"), {});
};

export const sendFailedOdooResources = () => {
  return doPost(
    "/admin/odoo/resources/failed/send",
    {},
    localStorage.getItem("access_token")
  );
};
// ==================== Home API's end ==============================

// -------------------- Products API's-------------------------------
export const createProduct = (formData) => {
  return doPost("/products", formData, localStorage.getItem("access_token"));
};
export const updateProduct = (formData) => {
  return doPost(
    "/products/" + formData.get("id"),
    formData,
    localStorage.getItem("access_token")
  );
};
export const getProducts = (data) => {
  return doGet("/products", localStorage.getItem("access_token"), data);
};
export const deleteProduct = (id) => {
  return doDelete("/products/" + id, {}, localStorage.getItem("access_token"));
};
// =====================Products API's end===========================

// -------------------- Products Categories API's-------------------------------
export const createProductCategory = (data) => {
  return doPost(
    "/product-categories",
    data,
    localStorage.getItem("access_token")
  );
};
export const getProductCategories = (data) => {
  return doGet(
    "/product-categories",
    localStorage.getItem("access_token"),
    data
  );
};
export const deleteProductCategory = (id) => {
  return doDelete(
    "/product-categories/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// =====================Products Categories API's end===========================

// -------------------- Products Units API's-------------------------------
export const createProductUnit = (data) => {
  return doPost("/product-units", data, localStorage.getItem("access_token"));
};
export const getProductUnits = (data) => {
  return doGet("/product-units", localStorage.getItem("access_token"), data);
};
export const deleteProductUnit = (id) => {
  return doDelete(
    "/product-units/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// =====================Products Units API's end===========================

// --------------------- Transactions API's -------------------------------
export const getTransactions = (data) => {
  return doGet("/transactions", localStorage.getItem("access_token"), data);
};
export const getTransactionQRCode = (transaction_id) => {
  return doGet(
    "/transactions/" + transaction_id + "/qrcode",
    localStorage.getItem("access_token")
  );
};
export const createTransactions = (data) => {
  return doPost("/transactions", data, localStorage.getItem("access_token"));
};
export const refundTransaction = (data) => {
  return doPost(
    "/transactions/" + data.transaction_id + "/refund/" + data.refund_type,
    data,
    localStorage.getItem("access_token")
  );
};
// ===================== Transactions API's end ===========================

// --------------------- Subscriptions API's -------------------------------
export const getSubscriptionsList = (data) => {
  return doGet(
    "/admin/companies/subscriptions/list",
    localStorage.getItem("access_token"),
    data
  );
};

export const deleteSubscription = (subscription_id) => {
  return doDelete(
    "/admin/subscriptions/" + subscription_id,
    {},
    localStorage.getItem("access_token")
  );
};

export const generateInvoice = (subscription_id) => {
  return doPost(
    "/admin/invoices",
    { subscription_id },
    localStorage.getItem("access_token")
  );
};

export const SubscribeYearlyTrial = (company_id) => {
  return doPost(
    "/admin/companies/" + company_id + "/subscriptions/activate-annual",
    {},
    localStorage.getItem("access_token")
  );
};

export const ExtendToYearlyTrial = (company_id) => {
  return doPost(
    "/admin/companies/" + company_id + "/subscriptions/trial/extend",
    {},
    localStorage.getItem("access_token")
  );
};

// ===================== Subscriptions API's end ===========================

// --------------------- Invoices pay API's ------------------------------------

export const invoicesPay = (invoice_id, data) => {
  return doPost(
    "/admin/invoices/" + invoice_id + "/mark-as-paid",
    data,
    localStorage.getItem("access_token")
  );
};

// ===================== Invoices pay API's end ===========================

// --------------------- Companies API's ------------------------------------

export const getCompanies = (data) => {
  return doGet("/admin/companies", localStorage.getItem("access_token"), data);
};
export const getCompany = (company_id, data) => {
  return doGet(
    "/admin/companies/" + company_id,
    localStorage.getItem("access_token"),
    data
  );
};
export const updateCompany = (data) => {
  return doPost(
    "/admin/companies/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
export const updateCompanyDetails = (CompanyId, data) => {
  return doPost(
    "/admin/companies/" + CompanyId + "/update-details",
    data,
    localStorage.getItem("access_token"),
    true
  );
};
export const activateCompany = (company_id) => {
  return doPost(
    "/admin/companies/" + company_id + "/activate",
    {},
    localStorage.getItem("access_token")
  );
};
export const deactivateCompany = (company_id) => {
  return doPost(
    "/admin/companies/" + company_id + "/deactivate",
    {},
    localStorage.getItem("access_token")
  );
};
export const deleteCompany = (id) => {
  return doDelete(
    "/admin/companies/" + id + "/destroy",
    {},
    localStorage.getItem("access_token")
  );
};
export const deleteUpload = (id, data) => {
  return doPost(
    "/admin/companies/" + id + "/uploaded/file/delete",
    data,
    localStorage.getItem("access_token")
  );
};
export const updateCompanyCSR = (data) => {
  return doPost(
    "/admin/companies/" + data.company_id + "/csr/" + data.staff_id + "/change",
    {},
    localStorage.getItem("access_token")
  );
};
export const sendUpdatedCompanyToOdoo = (company_id) => {
  return doPost(
    "/admin/odoo/companies/" + company_id + "/update",
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Companies API's end ================================

// --------------------- Branches API's ------------------------------------

export const getBranches = (data) => {
  return doGet("/branches", localStorage.getItem("access_token"), data);
};
export const createBranch = (companyID, data) => {
  return doPost(
    "/admin/companies/" + companyID + "/branches",
    data,
    localStorage.getItem("access_token")
  );
};
export const updateBranch = (companyID, data) => {
  return doPost(
    "/admin/companies/" + companyID + "/branches/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteBranch = (companyID, id) => {
  return doDelete(
    "/admin/companies/" + companyID + "/branches/" + id,
    {},
    localStorage.getItem("access_token")
  );
};

// ===================== Branches API's end ================================

// --------------------- Admins API's -------------------------------
export const createManager = (data) => {
  return doPost(
    "/super/admin/admins",
    data,
    localStorage.getItem("access_token")
  );
};
export const getManagers = (data) => {
  return doGet(
    "/super/admin/admins",
    localStorage.getItem("access_token"),
    data
  );
};
export const updateManager = (data) => {
  return doPost(
    "/super/admin/admins/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteManager = (id) => {
  return doDelete(
    "/super/admin/admins/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Admins API's end ===========================

// --------------------- Staffs API's -------------------------------
export const createStaff = (data) => {
  return doPost("/admin/staff", data, localStorage.getItem("access_token"));
};
export const getStaffs = (data) => {
  return doGet("/admin/staff", localStorage.getItem("access_token"), data);
};
export const updateStaff = (data) => {
  return doPost(
    "/admin/staff/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteStaff = (id) => {
  return doDelete(
    "/admin/staff/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Staffs API's end ===========================

// --------------------- Devices API's -------------------------------
export const createDevice = (data, companyId) => {
  return doPost(
    "/admin/companies/" + companyId + "/devices",
    data,
    localStorage.getItem("access_token")
  );
};

export const updateDevice = (data) => {
  return doPost(
    "/admin/companies/devices/" + data.deviceId,
    data,
    localStorage.getItem("access_token")
  );
};

export const deleteDevice = (id) => {
  return doDelete(
    "/admin/companies/devices/" + id,
    {},
    localStorage.getItem("access_token")
  );
};

export const GenerateDevicesInvoice = (companyId) => {
  return doPost(
    "/admin/invoices/companies/" +
      companyId +
      "/generate-devices-payment-invoice",
    {},
    localStorage.getItem("access_token")
  );
};

// ===================== Devices API's end ===========================

// --------------------- Reports API's ------------------------------------
export const getSalesSummary = (data) => {
  return doGet(
    "/reports/sales-summary",
    localStorage.getItem("access_token"),
    data
  );
};
export const getSalesByItems = (data) => {
  return doGet(
    "/reports/sales-by-items",
    localStorage.getItem("access_token"),
    data
  );
};
export const getSalesByCategories = (data) => {
  return doGet(
    "/reports/sales-by-categories",
    localStorage.getItem("access_token"),
    data
  );
};
export const getRefundsByItems = (data) => {
  return doGet(
    "/reports/refunds-by-items",
    localStorage.getItem("access_token"),
    data
  );
};
export const getRefundsByCategories = (data) => {
  return doGet(
    "/reports/refunds-by-categories",
    localStorage.getItem("access_token"),
    data
  );
};
// ===================== Reports API's end ================================

// --------------------- Regions API's -------------------------------
export const createRegion = (data) => {
  return doPost("/admin/regions", data, localStorage.getItem("access_token"));
};
export const getRegions = (data) => {
  return doGet("/admin/regions", localStorage.getItem("access_token"), data);
};
export const getRegion = (data) => {
  return doGet(
    "/admin/regions/" + data,
    localStorage.getItem("access_token"),
    {}
  );
};
export const updateRegion = (data) => {
  return doPost(
    "/admin/regions/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteRegion = (id) => {
  return doDelete(
    "/admin/regions/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Regions API's end ===========================

// --------------------- Cities API's -------------------------------
export const createCity = (region_id, data) => {
  return doPost(
    "/admin/regions/" + region_id + "/cities",
    data,
    localStorage.getItem("access_token")
  );
};
export const getCities = (region_id, data) => {
  return doGet(
    "/admin/regions/" + region_id + "/cities",
    localStorage.getItem("access_token"),
    data
  );
};
export const updateCity = (region_id, data) => {
  return doPost(
    "/admin/regions/" + region_id + "/cities/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteCity = (region_id, id) => {
  return doDelete(
    "/admin/regions/" + region_id + "/cities/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Cities API's end ===========================

// --------------------- Helpdesk API's -------------------------------

export const getTicketsCount = (data) => {
  return doGet(
    "/support/agent/helpdesk/tickets",
    localStorage.getItem("access_token"),
    data
  );
};
export const getNewTickets = (page) => {
  return doGet(
    "/support/agent/helpdesk/tickets/new?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getInprogressTickets = (page) => {
  return doGet(
    "/support/agent/helpdesk/tickets/inprogress?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getDoneTickets = (page) => {
  return doGet(
    "/support/agent/helpdesk/tickets/done?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getClosedTickets = (page) => {
  return doGet(
    "/support/agent/helpdesk/tickets/closed?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getLateTickets = (page) => {
  return doGet(
    "/support/agent/helpdesk/tickets/late?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getDelayedTickets = (page) => {
  return doGet(
    "/support/agent/helpdesk/tickets/delayed?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const updateTickets = (id, data) => {
  return doPost(
    "/support/agent/helpdesk/tickets/" + id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};

// ===================== Helpdesk API's end ===========================

// --------------------- Issue Types API's -------------------------------
export const createIssueType = (data) => {
  return doPost(
    "/admin/issue/types",
    data,
    localStorage.getItem("access_token")
  );
};
export const getIssueTypes = (data) => {
  return doGet(
    "/support/agent/issue/types",
    localStorage.getItem("access_token"),
    data
  );
};
export const updateIssueType = (data) => {
  return doPost(
    "/admin/issue/types/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
// ===================== Issue Types API's end ===========================

// --------------------- Business Types API's -------------------------------
export const createBusinessType = (data) => {
  return doPost(
    "/admin/business/type/verifications",
    data,
    localStorage.getItem("access_token")
  );
};
export const getBusinessTypes = (data) => {
  return doGet(
    "/admin/business/type/verifications",
    localStorage.getItem("access_token"),
    data
  );
};
// ===================== Business Types API's end ===========================

// --------------------- Activity Type API's -------------------------------
export const createActivityType = (data) => {
  return doPost(
    "/admin/crm/activity/types",
    data,
    localStorage.getItem("access_token")
  );
};
export const getActivityTypes = () => {
  return doGet(
    "/admin/crm/activity/types",
    localStorage.getItem("access_token"),
    {}
  );
};
export const getActivityType = (data) => {
  return doGet(
    "/admin/crm/activity/types/" + data.id,
    localStorage.getItem("access_token"),
    data
  );
};
export const updateActivityType = (data) => {
  return doPost(
    "/admin/crm/activity/types/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
// ===================== Activity Type API's end ===========================

// --------------------- Features API's -------------------------------
export const updateFeature = (data) => {
  return doPost(
    "/super/admin/custom/features/" + data.get("id"),
    data,
    localStorage.getItem("access_token")
  );
};
// ===================== Features API's end ===========================

// --------------------- Note API's -------------------------------
export const createNote = (companyId, data) => {
  return doPost(
    "/admin/crm/companies/" + companyId + "/notes",
    data,
    localStorage.getItem("access_token")
  );
};
export const getNotes = (companyId, page) => {
  return doGet(
    "/admin/crm/companies/" + companyId + "/notes?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const updateNote = (companyId, data) => {
  return doPost(
    "/admin/crm/companies/" + companyId + "/notes/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteNote = (companyId, id) => {
  return doDelete(
    "/admin/crm/companies/" + companyId + "/notes/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Note API's end ===========================

// ---------------------  Questionnaire API's -------------------------------
export const postQuestionnaireData = (companyId, data) => {
  return doPost(
    "/admin/questionnaires/companies/" + companyId,
    data,
    localStorage.getItem("access_token")
  );
};
export const getQuestionnaireData = (companyId) => {
  return doGet(
    "/admin/questionnaires/companies/" + companyId,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getLearningSources = () => {
  return doGet(
    "/admin/learning/sources",
    localStorage.getItem("access_token"),
    {}
  );
};
// =====================  Questionnaire API's end ===========================

// --------------------- Activity API's -------------------------------
export const createActivity = (companyId, data) => {
  return doPost(
    "/admin/crm/companies/" + companyId + "/activities",
    data,
    localStorage.getItem("access_token")
  );
};
export const getActivities = (companyId, page) => {
  return doGet(
    "/admin/crm/companies/" + companyId + "/activities?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getAllActivities = (page) => {
  return doGet(
    "/admin/crm/activities/all?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const updateActivity = (companyId, data) => {
  return doPost(
    "/admin/crm/companies/" + companyId + "/activities/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const updateActivityStatus = (companyId, data) => {
  return doPost(
    "/admin/crm/companies/" +
      companyId +
      "/activities/" +
      data.get("id") +
      "/status/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteActivity = (companyId, id) => {
  return doDelete(
    "/admin/crm/companies/" + companyId + "/activities/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Activity API's end ===========================

// --------------------- Comment API's -------------------------------
export const createComment = (data) => {
  return doPost(
    "/admin/crm/comments",
    data,
    localStorage.getItem("access_token")
  );
};
export const getNoteComments = (id) => {
  return doGet(
    "/admin/crm/notes/" + id + "/comments",
    localStorage.getItem("access_token"),
    {}
  );
};
export const getActivityComments = (id) => {
  return doGet(
    "/admin/crm/activities/" + id + "/comments",
    localStorage.getItem("access_token"),
    {}
  );
};
export const deleteComment = (id) => {
  return doDelete(
    "/admin/crm/comments/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Comment API's end ===========================

// --------------------- Logs API's -------------------------------
export const getLogs = (companyId, page) => {
  return doGet(
    "/admin/crm/companies/" + companyId + "/logs?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
// ===================== Logs API's end ===========================

// ---------------------  All Users API's -------------------------------
export const getAllUsers = () => {
  return doGet("/admin/crew", localStorage.getItem("access_token"), {});
};
// =====================  All Users API's end ===========================

// ---------------------  Notifications API's -------------------------------
export const getNotifications = (page) => {
  return doGet(
    "/notifications?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};

export const markNotificationAsRead = (notificationId) => {
  return doPost(
    "/notifications/" + notificationId + "/markAsRead",
    {},
    localStorage.getItem("access_token")
  );
};

export const markAllNotificationsAsRead = () => {
  return doPost(
    "/notifications/markAllAsRead",
    {},
    localStorage.getItem("access_token")
  );
};
// =====================  Notifications API's end ===========================

// ---------------------  System Settings API's -------------------------------

export const showSystemSettings = () => {
  return doGet(
    "/super/admin/system/settings",
    localStorage.getItem("access_token"),
    {}
  );
};

export const updateSystemSettings = (data) => {
  return doPost(
    "/super/admin/system/settings",
    data,
    localStorage.getItem("access_token")
  );
};

// =====================  System Settings API's end ===========================

// ---------------------  Subscription Plans API's -------------------------------

export const showSubscriptionPlans = () => {
  return doGet("/subscription-plans", localStorage.getItem("access_token"), {});
};

export const updateSubscriptionPlans = (data) => {
  return doPost(
    "/super/admin/subscription/plans/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};

// =====================  Subscription Plans API's end ===========================

// ---------------------  Addon API's -------------------------------

export const createAddon = (data) => {
  return doPost(
    "/super/admin/addons",
    data,
    localStorage.getItem("access_token"),
    true
  );
};

export const updateAddon = (data) => {
  return doPost(
    "/super/admin/addons/" + data.get("id"),
    data,
    localStorage.getItem("access_token"),
    true
  );
};

export const showAddons = () => {
  return doGet("/addons", localStorage.getItem("access_token"), {});
};

// =====================  Addon API's end ===========================
