import {
  USER_TYPE_MANAGER,
  USER_TYPE_ADMIN_STAFF,
  USER_TYPE_SUPER_ADMIN,
} from "./configs/constants";
import Login from "./pages/Auth/Login/Login";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "SAR",
});

export const filterPagesByAddons = (ContentPages, authUser) => {
  if (!authUser) {
    return [
      {
        path: "/login",
        name: "login",
        text: "login",
        component: Login,
        icon: false,
        showInSider: false,
      },
      {
        path: "*",
        name: "login",
        text: "login",
        component: Login,
        icon: false,
        showInSider: false,
      },
    ];
  }
  return ContentPages.filter((page) => {
    // route show only for non support agent
    if (
      [
        "home",
        "companies",
        "companies_company",
        "subscriptions",
        "activities",
      ].includes(page.name) &&
      authUser.type === USER_TYPE_ADMIN_STAFF &&
      authUser.is_support_agent === 0
    ) {
      return true;
    }
    // route show only for support agent
    else if (
      [
        "home",
        "companies",
        "companies_company",
        "subscriptions",
        "helpdesk",
        "activities",
      ].includes(page.name) &&
      authUser.type === USER_TYPE_ADMIN_STAFF &&
      authUser.is_support_agent === 1
    ) {
      return true;
    }
    // route show only for managers
    else if (
      [
        "home",
        "companies",
        "companies_company",
        "subscriptions",
        "helpdesk",
        "activities",
        "activity_type",
        "configuration",
        "region",
        "cities",
        "business_type_verifications",
        "issue_types",
        "access_management",
        "staff",
      ].includes(page.name) &&
      authUser.type === USER_TYPE_MANAGER
    ) {
      return true;
    }
    // all route show only for super admin
    else if (authUser.type === USER_TYPE_SUPER_ADMIN) {
      return true;
    } else {
      return false;
    }
  });
};
