import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Tabs,
  Modal,
  DatePicker,
  Select,
  Pagination,
  Radio,
  Empty,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useParams } from "react-router-dom";
import {
  createActivity,
  getActivities,
  getActivityTypes,
  getAllUsers,
  updateActivity,
} from "../../../network/network";
import FindActivityTypeIcon from "../../../components/FindActivityTypeIcon";

const CompanyActivities = () => {
  const ADD_ACTIVITY = "Add Activity";
  const [modalVisible, setModalVisible] = useState(false);
  const [activityForm] = Form.useForm();
  const [activitiesData, setactivitiesData] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [reloadactivitiesData, setReloadactivitiesData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [activityType, setActivityType] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  let { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    getActivities(companyId, paginationPage)
      .then((response) => {
        setactivitiesData(response.data.data.activities);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadactivitiesData, companyId, paginationPage]);

  useEffect(() => {
    setLoading(true);
    getActivityTypes()
      .then((response) => {
        setActivityTypes(response.data.data.activity_types);
      })
      .finally(() => {
        setLoading(false);
      });
    getAllUsers()
      .then((response) => {
        setAllUsers(response.data.data.crew);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePagination = (page, pageSize) => {
    setPaginationPage(page);
  };

  const showModal = () => {
    // Show activity Modal
    setModalVisible(true);
    // Set add activity Modal Title
    setModalTitle(t("activity_add"));
    // Reset activity Form Fields
    activityForm.resetFields();
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadactivitiesData(!reloadactivitiesData);
      // Success
      if (type === ADD_ACTIVITY) {
        NotificationWithIcon(
          "success",
          t("activity_added"),
          response.data.message
          // t("activity_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("activity_updated"),
          response.data.message
          // t("activity_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    activityForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    activityForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        if (activityType) {
          activityForm.setFieldsValue({
            type: activityType,
          });
        }

        // set start_end_date value to seprate start and end date
        values.start_date = values.start_end_date[0];
        values.end_date = values.start_end_date[1];
        // Sending Request to API
        if (modalTitle === ADD_ACTIVITY || modalTitle === t("activity_add")) {
          createActivity(companyId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, ADD_ACTIVITY);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateActivity(companyId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    activityForm.resetFields();
    // close modal
    setModalVisible(false);
    // reset activity type
    setActivityType(false);
  };

  const disabledDate = (current) => {
    return current && current.valueOf() < Date.now();
  };

  const tabItems = [
    {
      label: `All (${activitiesData.length})`,
      key: "tab-all",
      children:
        activitiesData.length > 0 ? (
          <>
            {activitiesData.map((activity) => (
              <div
                className="activity_card"
                style={{
                  boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                  margin: "20px",
                  borderRadius: "10px",
                }}
                key={activity.id}
              >
                {/* Activity card content */}
              </div>
            ))}
            {paginationData.current_page &&
              paginationData.count > 0 &&
              paginationData.total > paginationData.per_page && (
                <Pagination
                  current={paginationData.current_page}
                  total={paginationData.total}
                  pageSize={paginationData.per_page}
                  onChange={handlePagination}
                  showSizeChanger={false}
                  defaultCurrent={paginationData.current_page}
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                />
              )}
          </>
        ) : (
          <Empty />
        ),
    },
  ];

  return (
    <>
      <Spin size="large" spinning={loading}>
        <div style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            <PlusOutlined title={t("activity_add")} /> {t("activity_add")}
          </Button>
        </div>
        <Tabs defaultActiveKey="tab-all" centered items={tabItems} />

        {/* add or edit activity */}
        <Modal
          open={modalVisible}
          title={modalTitle}
          onOk={handleAddEdit}
          onCancel={handleCancel}
          style={{ top: 10 }}
          footer={[
            <Button key="back" loading={loading} onClick={handleCancel}>
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddEdit}
            >
              {modalTitle === ADD_ACTIVITY || modalTitle === t("activity_add")
                ? t("activity_add")
                : t("update")}
            </Button>,
          ]}
        >
          <Form
            name="activity-form"
            layout="vertical"
            onFinish={handleAddEdit}
            style={{ width: "100%", margin: "0" }}
            form={activityForm}
          >
            <Form.Item
              name="title"
              label={t("title")}
              rules={[
                {
                  required: true,
                  message: t("validate_title_required"),
                  whitespace: true,
                },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="activity_type_id"
              label={t("type")}
              rules={[
                {
                  required: true,
                  message: t("validate_activity_type_required"),
                },
              ]}
            >
              <Radio.Group
                options={activityTypes.map((type) => {
                  return {
                    label: (
                      <FindActivityTypeIcon
                        name={type.icon}
                        title={type.title}
                      />
                    ),
                    // label: type.icon,
                    value: type.id,
                    key: type.id,
                  };
                })}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
                optionType="button"
                buttonStyle="solid"
              ></Radio.Group>
            </Form.Item>
            <Form.Item
              name="start_end_date"
              label={t("date")}
              rules={[{ required: true, message: t("validate_date_required") }]}
            >
              <RangePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                format="DD-MM-YYYY HH:mm"
                allowClear
              />
            </Form.Item>
            <Form.Item name="reminder" label={t("reminder")}>
              <DatePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                format="DD-MM-YYYY HH:mm"
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="assigned_to"
              style={{ width: "100%" }}
              label={t("assigned_to")}
              rules={[
                {
                  required: true,
                  message: t("validate_assigned_to_required"),
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                options={allUsers.map((user) => {
                  return {
                    label: user.name,
                    value: user.id,
                  };
                })}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={t("description")}
              rules={[
                { required: true, message: t("validate_description_required") },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item name="id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

export default CompanyActivities;
