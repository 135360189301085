import { useState } from "react";
import {
  createBranch,
  deleteBranch,
  updateBranch,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { Form, Button, Table, Modal, Input, Popconfirm } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const BranchesTableWithEditModal = ({
  company,
  branches,
  reloadCallback,
  isDisabled = false,
}) => {
  const ADD_BRANCH = "Add Branch";
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState(ADD_BRANCH);
  const [addStoreForm] = Form.useForm();
  const { t } = useTranslation();

  const BranchesTableColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      width: "40%",
    },
    {
      title: t("address"),
      dataIndex: "address",
      width: "30%",
    },
    {
      title: t("code"),
      dataIndex: "code",
      width: "10%",
    },
    {
      title: t("col_action"),
      width: "15%",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("branch_edit")}
              disabled={isDisabled}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDeleteBranch(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
              disabled={isDisabled}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("branch_add"));
    // Reset Form Fields
    addStoreForm.resetFields();
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      // Reload Callback
      reloadCallback();
      // Success
      if (type === ADD_BRANCH) {
        NotificationWithIcon(
          "success",
          t("branch_added"),
          t("branch_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("branch_updated"),
          t("branch_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    addStoreForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    addStoreForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_BRANCH || modalTitle === t("branch_add")) {
          createBranch(company.id, values)
            .then((response) => handleAPIResponse(response, ADD_BRANCH))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateBranch(company.id, values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (id) => {
    // Find branch from DataArray
    const branch = branches.find((branch) => branch.id === id);

    // Set Modal Title
    setModalTitle(t("branch_edit") + " : " + branch.name);

    addStoreForm.resetFields();
    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    addStoreForm.setFieldsValue({
      id: branch.id,
      name: branch.name,
      address: branch.address,
      code: branch.code,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    addStoreForm.resetFields();
    setModalVisible(false);
  };

  const handleDeleteBranch = (id) => {
    deleteBranch(company.id, id).then((response) => {
      if (response?.data?.success === true) {
        // Success
        NotificationWithIcon(
          "success",
          t("branch_deleted"),
          t("branch_deleted_successfully")
        );
        // Reload Callback
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response?.response?.data?.message
        );
      }
    });
  };

  return (
    <>
      {/* {Object.values(company.addons).indexOf("multi_branch") > -1 && ( */}
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal} disabled={isDisabled}>
          {t("branch_add")}
        </Button>
      </div>
      {/* )} */}

      <Table
        columns={BranchesTableColumns}
        dataSource={branches}
        rowKey="id"
        pagination={false}
      />
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_BRANCH || modalTitle === t("branch_add")
              ? t("branch_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-branch-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={addStoreForm}
        >
          <Form.Item
            name="name"
            label={t("name")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label={t("address")}
            rules={[
              {
                required: true,
                message: t("validate_address_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item name="code" label={t("code")} hasFeedback>
            <Input />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BranchesTableWithEditModal;
