const trans_en_GB = {
  // Form Fields
  name: "Name",
  name_ar: "Name in Arabic",
  name_en: "Name in English",
  first_name: "First Name",
  last_name: "Last Name",
  price: "Price",
  email: "E-mail",
  password: "Password",
  confirm_password: "Confirm Password",
  phone_number: "Phone Number",
  business_name: "Business Name",
  business_type: "Business Type",
  shop_address: "Shop Address",
  cr_number: "CR number",
  cr_certificate: "CR Certificate",
  cr_verification: "CR Verification",
  vat_number: "VAT Number",
  vat_certificate: "VAT Certificate",
  vat_verification: "VAT Verification",
  business_logo: "Business Logo",
  image: "Image",
  is_taxable: "Is Taxable",
  is_refunded: "Is Refunded",
  is_support_agent: "Is Support Agent",
  can_manage_all_regions: "Manage All Regions",
  reset: "Reset",
  cart: "Cart",
  change: "Change",
  preview: "Preview",
  company: "Company",
  addons: "Addons",
  no_of_employees: "# of Employees",
  no_of_branches: "# of Branches",
  no_of_licenses: "# of Licenses",
  branches: "Branches",
  pos: "POS",
  multi_branch: "Multi-Branch",
  type: "Type",
  branch: "Branch",
  owner: "Owner",
  employee: "Employee",
  active: "Active",
  inactive: "Inactive",
  address: "Address",
  code: "Code",
  requested: "Requested",
  status: "Status",
  comment: "Comment",
  title: "Title",
  date: "Date",
  reminder: "Reminder",
  assigned_to: "Assigned To",
  description: "Description",
  icon: "Icon",
  discount: "Discount",
  user_price: "User Price",
  user_discount: "User Discount",
  validity_days: "Validity Days",
  billing_cycle: "Billing Cycle",
  trial_days: "Trial Days",

  model: "Model",
  imei: "IMEI",
  serial_no: "Serial No.",
  amount: "Amount",
  device_amount: "Amount (Net Amount after Discout and VAT must be excluded)",
  due_amount: "Due Amount",
  installments: "Installments",
  warranty_from_to: "Warranty From - To",
  warranty_starting_at: "Warranty Start",
  warranty_ending_at: "Warranty End",
  device_add: "Add New Device",
  subscribe_to_yearly_trial: "Subscribe to Yearly Trial",
  billing_address: "Billing Address",
  billing_city: "Billing City",
  billing_state: "Billing State",
  billing_country: "Billing Country",
  billing_post_code: "Billing Postal Code",
  allow_round_off: "Allow round off to 2 digits",
  preview_cr_certificate: "Preview CR Certificate",
  preview_vat_certificate: "Preview VAT Certificate",
  preview_cr_verification: "Preview CR Verification",
  preview_vat_verification: "Preview VAT Verification",
  generate_devices_invoice: "Generate Devices Invoice",
  invoice_ID: "Invoice ID",
  subscription: "Subscription",
  license: "License",
  total_amount_charged: "Total Amount Charged",
  invoices: "Invoices",
  country: "Country",
  no_attachment: "No Attachment",
  preferred_contact_time: "Preferred contact time",
  how_did_you_learn_about_AnyPOS: "How did you learn about AnyPOS?",
  other_platform: "Other Platform",
  your_preferred_platform: "Your preferred platform for accessing AnyPOS.",
  is_your_business_newly_established_or_existing:
    "Is your business newly established or existing?",

  // Branches
  branch_add: "Add Branch",
  branch_added: "Branch Added",
  branch_added_successfully: "Branch has been added successfully!",
  branch_deleted: "Branch Deleted",
  branch_deleted_successfully: "Branch has been deleted successfully!",
  branch_edit: "Edit Branch",
  branch_updated: "Branch Updated",
  branch_updated_successfully: "Branch has been updated successfully!",

  // Form Validations
  validate_email_required: "The email field is required.",
  validate_email_invalid: "The input is not a valid E-mail!",
  validate_password_required: "The password field is required.",
  validate_password_min_characters: "password must be of minimum 6 characters.",
  validate_password_confirm_not_matched: "Confirm password does not matched.",
  validate_name_required: "The name field is required.",
  validate_name_min_characters: "Name must be of minimum 3 characters.",
  validate_first_name_required: "The First name field is required.",
  validate_first_name_min_characters:
    "First name must be of minimum 3 characters.",
  validate_last_name_required: "The Last name field is required.",
  validate_last_name_min_characters:
    "Last name must be of minimum 3 characters.",
  validate_phone_required: "The phone number field is required.",
  validate_phone_exact_limit: "Phone must be of exactly 12 digits.",
  validate_region_required: "The Region field is required.",
  validate_city_required: "The City field is required.",
  validate_status_required: "The Status field is required.",
  validate_support_agent_required: "The support agent field is required.",
  validate_manage_all_regions_required:
    "The manage all regions field is required.",
  validate_business_name_required: "The business name field is required.",
  validate_business_type_required: "Please select business type.",
  validate_cr_number_required: "The CR# field is required.",
  validate_cr_number_exact_limit: "The CR# must be of exactly 10 Number.",
  validate_vat_number_required: "The VAT field is required.",
  validate_vat_number_exact_limit: "The VAT# must be of exactly 15 Number.",
  validate_address: "The address field is required.",
  validate_shop_address_required: "Please input your Shop Address!",
  validate_image_file_type: "You can only upload JPG/PNG file!",
  validate_image_size: "Image must smaller than 2MB!",
  validate_business_logo_required: "Business Logo is required!",
  validate_cr_certificate_required: "CR Certificate is required!",
  validate_vat_certificate_required: "VAT Certificate is required!",
  validate_image_pdf_file_type: "You can only upload JPG/PNG or PDF file!",
  validate_image_size_8: "Image must smaller than 8MB!",
  validate_billing_address_required: "The Billing Address field is required.",
  validate_billing_city_required: "The Billing City field is required.",
  validate_billing_state_required: "The Billing State field is required.",
  validate_billing_country_required: "The Billing Country field is required.",
  validate_billing_post_code_required:
    "The Billing Postal Code field is required.",
  validate_address_required: "Please input Address!",
  validate_paid_reason_required: "Please Input the valid paid reason",
  validate_country_required: "The country field is required.",
  validate_country_min_characters:
    "Country name must be of minimum 3 characters.",
  validate_price_required: "The price field is required.",
  validate_discount_required: "The Discount field is required.",
  validate_product_name_required: "Please input Product name!",
  validate_product_price_required: "Please input Product price!",
  validate_product_barcode_required: "Please input Product barcode!",
  validate_product_category_required: "Please select Product category!",
  validate_product_unit_required: "Please select Product unit!",
  validate_comment_required: "The comment field is required.",
  validate_issue_types_required: "The issue types field is required.",
  validate_issue_types_min_characters:
    "The issue types field must be of minimum 3 characters.",
  validate_business_types_required: "The business types field is required.",
  validate_business_types_min_characters:
    "The business types field must be of minimum 3 characters.",
  validate_title_required: "The title field is required.",
  validate_activity_type_required: "The activity type is required.",
  validate_date_required: "The date field is required.",
  validate_reminder_required: "The reminder field is required.",
  validate_owner_required: "The owner field is required.",
  validate_assigned_to_required:
    "Please select whom activity will assigned to.",
  validate_description_required: "The description field is required.",
  validate_icon_required: "The icon is required.",
  validate_company_staff_required: "The company staff field is required.",
  validate_learning_source_required: "The learning source field is required.",
  validate_preferred_platform_required:
    "The preferred platform field is required.",
  validate_new_or_existing_required:
    "The Business Established field is required.",
  validate_trial_days_required: "The trial days field is required.",

  // Company Devices modal field
  validate_model_required: "The Model field is required.",
  validate_imei_required: "The IMEI field is required.",
  validate_serial_no_required: "The Serial No. field is required.",
  validate_amount_required: "The Amount field is required.",
  validate_warranty_starting_required:
    "The Warranty Starting field is required.",
  validate_warranty_ending__required: "The Warranty Ending field is required.",

  // Login
  dont_have_account: "Don't have an account?",
  login: "Login",

  // Register
  already_have_an_account: "Already have an account?",
  sign_up: "Sign up",
  register_successfully: "You have been Registered Successfully!",

  // Store Configuration
  beauty_and_personal_care: "Beauty & Personal Care",
  retail_food_drinks: "Retail - Food - Drinks",
  healthcare_and_fitness: "Healthcare and Fitness",
  home_and_repair: "Home and Repair",
  entertainment: "Entertainment",
  professional_services: "Professional Services",
  transportation: "Transportation",
  shop_configuration_successfully: "Shop configuration has been completed.",

  // Home
  send_Failed_Odoo_Resources: "Send Failed Odoo Resources",
  send_Failed_Odoo_Resources_successfully:
    "Failed Odoo Resources has been send successfully!",

  // Menus
  menu_home: "Home",
  menu_pos: "POS",
  menu_products: "Products",
  menu_product_categories: "Product Categories",
  menu_product_units: "Product Units",
  menu_transactions: "Transactions",
  menu_reports: "Reports",
  menu_sales_summary: "Sales Summary",
  menu_sales_by_items: "Sales By Items",
  menu_sales_by_categories: "Sales By Categories",
  menu_refunds_by_items: "Refunds By Items",
  menu_refunds_by_categories: "Refunds By Categories",
  menu_store_configuration: "Store Configuration",
  menu_subscriptions: "Subscriptions",
  menu_companies: "Companies",
  menu_employees: "Employees",
  menu_branches: "Branches",
  menu_managers: "Managers",
  menu_staffs: "Staffs",
  menu_business_type_verifications: "Business Type Verifications",
  menu_issue_types: "Issue Types",
  menu_regions: "Regions",
  menu_cities: "Cities",
  menu_help_desk: "Help Desk",
  menu_configuration: "Configuration",
  menu_access_management: "Access Management",
  menu_activities: "All Activities",
  menu_activity_type: "Activity Type",
  menu_features: "Features",
  menu_system_settings: "System Settings",
  menu_subscription_plans: "Subscription Plans",
  menu_addons: "Addons",

  // Products
  product_add: "Add Product",
  product_added: "Product Added",
  product_added_successfully: "Product has been added successfully!",
  product_edit: "Edit Product",
  product_updated: "Product Updated",
  product_updated_successfully: "Product has been updated successfully!",
  product_deleted: "Product Deleted",
  product_deleted_successfully: "Product has been deleted successfully!",

  // Product Categories
  category_add: "Add Category",
  category_name: "Category Name",
  category_name_required: "Please input product category name!",
  category_added: "Category Added",
  category_added_successfully: "Category has been added successfully!",
  category_deleted: "Category Deleted",
  category_deleted_successfully: "Category has been deleted successfully!",

  // Product Units
  unit_add: "Add Unit",
  unit_name: "Unit Name",
  unit_name_required: "Please input product unit name!",
  unit_added: "Unit Added",
  unit_added_successfully: "Unit has been added successfully!",
  unit_deleted: "Unit Deleted",
  unit_deleted_successfully: "Unit has been deleted successfully!",

  // Company
  update_csr: "Update CSR",
  select_company_staff: "Select Company Staff",
  visit_website_for_verification: "Visit Website for Verification",
  edit_company_details: "Edit Company Details",
  company_updated: "Company Updated",
  company_updated_successfully: "Company has been updated successfully!",
  manage_addons: "Manage Addons",
  company_activate: "Activate Company",
  company_activated: "Company Activated",
  company_activated_successfully: "Company has been activated successfully!",
  company_deactivate: "Deactivate Company",
  company_deactivated: "Company Deactivated",
  company_deactivated_successfully:
    "Company has been deactivated successfully!",
  search_company_name_here: "Search company name here",
  search_cr_here: "Search CR number here",
  search_phone_here: "Search phone number here",
  company_deleted: "Deleted Company",
  company_deleted_successfully: "Company has been deleted successfully!",
  update_company_on_odoo: "Update Company on Odoo",
  update_company_on_odoo_message:
    "Company is being sent to Odoo in background.",
  upload_deleted: "Deleted Upload",
  upload_deleted_successfully: "Upload has been deleted successfully!",

  // Subscriptions
  subscription_request_deleted: "Subscription Request Deleted",
  subscription_request_deleted_successfully:
    "Subscription request has been deleted successfully!",
  subscription_request_accepted: "Subscription Request Accepted",
  subscription_request_accepted_successfully:
    "Subscription request has been accepted successfully!",

  // Company Devices
  device_added: "Device Added",
  device_added_successfully: "Device has been added successfully!",
  device_updated: "Device Updated",
  device_updated_successfully: "Device has been updated successfully!",
  device_deleted: "Device Deleted",
  device_deleted_successfully: "Device has been deleted successfully!",

  // invoices
  invoice_paid: "Invoice Marked as Paid.",
  invoice_paid_successfully: "Invoice has been Paid successfully!",
  generate_invoice_request_accepted: "Generate Invoice Request Accepted",
  generate_invoice_request_accepted_successfully:
    "Generate Invoice Request has been accepted successfully!",
  pay_now: "Pay Now!",
  pay_invoice: "Pay Invoice",
  paid_reason: "Paid Reason",

  // Table Columns
  col_product: "Product",
  col_price: "Price",
  col_category: "Category",
  col_unit: "Unit",
  col_barcode: "Barcode",
  col_action: "Action",
  col_order_id: "Order ID",
  col_amount: "Amount",
  col_tax: "Tax",
  col_type: "Type",
  col_quantity: "Quantity",
  col_status: "Status",
  col_created_at: "Created At",
  col_subtotal: "SubTotal",
  col_sold_quantities: "Sold Quantities",
  col_gross_sales: "Gross Sales",
  col_refund_quantities: "Refund Quantities",
  col_gross_refund: "Gross Refunds",
  col_start_date: "Start Date",
  col_end_date: "End Date",
  col_subscription_plan: "Subscription Plan",
  col_transaction_id: "Transaction ID",
  col_items: "Items",
  col_region: "Region",
  col_cities: "Cities",
  col_payment: "Payment",
  col_description: "Description",
  col_attachment: "Attachment",
  col_contact: "Contact",
  col_customer_name: "Customer Name",
  col_csr_name: "CSR Name",

  // Reports
  gross_sales: "Gross Sales",
  net_sales: "Net Sales",
  total_sales: "Total Sales",
  refunds: "Refunds",
  taxes: "Taxes",
  sales: "Sales",
  payments: "Payments",
  net_total: "Net Total",
  total_refunds: "Total Refunds",
  total: "Total",
  vat: "VAT",
  total_vat: "Total VAT",
  quarterly_vat: "Quarterly VAT",
  payment_types: "Payment Types",
  quarterly_sales: "Quarterly Sales",
  sales_overview: "Sales Overview",

  // Warnings
  warning_delete: "Are you sure to delete this entity?",

  // Status
  status_paid: "PAID",
  status_refunded: "REFUNDED",

  // Miscellaneous
  yes: "Yes",
  no: "No",
  logout: "Logout",
  cancel: "Cancel",
  add: "Add",
  update: "Update",
  save: "Save",
  edit: "Edit",
  delete: "Delete",
  search: "Search",
  upload: "Upload",
  back: "Back",
  order_id: "Order ID",
  order_details: "Order Details",
  issued_at: "Issued At",
  date_range: "Date Range",
  delete_entity: "Delete Entity",
  something_went_wrong: "Something went wrong",
  refund: "Refund",
  view_company: "View Company",
  proceed: "Proceed",
  checkout: "Checkout",
  payment_type: "Payment Type",
  cash_collected: "Cash Collected",
  reference_no: "Reference No.",
  charged_amount: "Charged Amount",
  including_vat: "Including VAT",
  ask_more_cash: "Ask for more cash before proceeding!",
  return_back_amount: "Return Back",
  print: "Print",
  is_vat_exempt: "Is the VAT Exempt",
  complete: "Complete",
  incomplete: "In Complete",
  month: "Month",
  year: "Year",
  day: "Day",
  users: "Users",

  // manager
  manager_add: "Add Manager",
  manager_edit: "Edit Manager",
  manager_added: "Manager Added",
  manager_added_successfully: "Manager has been added successfully!",
  manager_updated: "Manager Updated",
  manager_updated_successfully: "Manager has been updated successfully!",
  manager_deleted: "Manager Deleted",
  manager_deleted_successfully: "Manager has been deleted successfully!",

  // staff
  staff_add: "Add Staff",
  staff_edit: "Edit Staff",
  staff_added: "Staff Added",
  staff_added_successfully: "Staff has been added successfully!",
  staff_updated: "Staff Updated",
  staff_updated_successfully: "Staff has been updated successfully!",
  staff_deleted: "Staff Deleted",
  staff_deleted_successfully: "Staff has been deleted successfully!",

  // issue type
  issue_type_add: "Add Issue Type",
  issue_type_edit: "Edit Issue Type",
  issue_type_added: "Issue Type Added",
  issue_type_added_successfully: "Issue Type has been added successfully!",
  issue_type_updated: "Issue Type Updated",
  issue_type_updated_successfully: "Issue Type has been updated successfully!",
  issue_type_deleted: "Issue Type Deleted",
  issue_type_deleted_successfully: "Issue Type has been deleted successfully!",
  add_deatils: "Add Deatils",
  add_issue: "Add Issue",

  // issue type
  business_type_add: "Add Business Type",
  business_type_edit: "Edit Business Type",
  business_type_added: "Business Type Added",
  business_type_added_successfully:
    "Business Type has been added successfully!",
  business_type_updated: "Issue Type Updated",
  business_type_updated_successfully:
    "Issue Type has been updated successfully!",
  business_type_deleted: "Issue Type Deleted",
  business_type_deleted_successfully:
    "Issue Type has been deleted successfully!",
  add_business: "Add Business",

  // Region
  region_add: "Add Region",
  region_edit: "Edit Region",
  region_added: "Region Added",
  region_added_successfully: "Region has been added successfully!",
  region_updated: "Region Updated",
  region_updated_successfully: "Region has been updated successfully!",
  region_deleted: "Region Deleted",
  region_deleted_successfully: "Region has been deleted successfully!",

  // city
  view_cities: "View Cities",
  city_add: "Add City",
  city_edit: "Edit City",
  city_added: "City Added",
  city_added_successfully: "City has been added successfully!",
  city_updated: "City Updated",
  city_updated_successfully: "City has been updated successfully!",
  city_deleted: "City Deleted",
  city_deleted_successfully: "City has been deleted successfully!",

  // Helpdesk
  new_tickets: "New Tickets",
  inprogress_tickets: "Tickets In Progress",
  done_tickets: "Tickets Done",
  closed_tickets: "Tickets Closed",
  ticket_updated: "Ticket Updated",
  ticket_updated_successfully: "The ticket has been updated successfully!",
  mark_as_issue: "Mark as Issue",
  status_updated_successfully: "Status updated Successfully!",
  helpdesk_ticket_status_updated_successfully:
    "Helpdesk Ticket status updated Successfully!",
  extend_to_yearly_trial: "Extend to Yearly Trial",

  // Activities
  activity_view: "View Activity",
  activity_add: "Add Activity",
  activity_edit: "Edit Activity",
  activity_completed: "Activity Completed",
  activity_completed_successfully: "Activity has been completed successfully!",
  activity_added: "Activity Added",
  activity_added_successfully: "Activity has been added successfully!",
  activity_updated: "Activity Updated",
  activity_updated_successfully: "Activity has been updated successfully!",
  activity_deleted: "Activity Deleted",
  activity_deleted_successfully: "Activity has been deleted successfully!",

  // Activities Type
  activity_type_add: "Add Activity Type",
  activity_type_edit: "Edit Activity Type",
  activity_type_added: "Activity Type Added",
  activity_type_added_successfully:
    "Activity Type has been added successfully!",
  activity_type_updated: "Activity Type Updated",
  activity_type_updated_successfully:
    "Activity Type has been updated successfully!",
  activity_type_deleted: "Activity Type Deleted",
  activity_type_deleted_successfully:
    "Activity Type has been deleted successfully!",

  // comment
  comment_add: "Add Comment",
  comment_added: "Comment Added",
  comment_added_successfully: "Comment has been added successfully!",
  comment_deleted: "Comment Deleted",
  comment_deleted_successfully: "Comment has been deleted successfully!",

  // note
  note_add: "Add Note",
  note_edit: "Edit Note",
  note_added: "Note Added",
  note_added_successfully: "Note has been added successfully!",
  note_updated: "Note Updated",
  note_updated_successfully: "Note has been updated successfully!",
  note_deleted: "Note Deleted",
  note_deleted_successfully: "Note has been deleted successfully!",

  // Features
  feature_updated: "Feature Updated",
  feature_updated_successfully: "Feature has been updated successfully!",
  sub_near_expiry: "Subscription near expiry",

  // System Settings
  settings_updated: "Settings Updated",
  settings_updated_successfully: "Settings has been updated successfully!",
  daily: "Daily",
  monthly_or_annually: "Monthly or Annually",

  // Subscription Plans
  subscription_updated: "Subscription Updated",
  subscription_updated_successfully:
    "Subscription has been updated successfully!",

  // Addon
  addon_add: "Add Addon",
  addon_edit: "Edit Addon",
  addon_added: "Addon Added",
  addon_added_successfully: "Addon has been added successfully!",
  addon_updated: "Addon Updated",
  addon_updated_successfully: "Addon has been updated successfully!",
};

export default trans_en_GB;
