import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

const CustomBreadcrumb = ({ items }) => {
  function itemRender(route, params, items, paths) {
    const last = items.indexOf(route) === items.length - 1;
    return last ? (
      <span>
        {route.icon ?? false}
        {route.icon && " "}
        {route.breadcrumbName}
      </span>
    ) : (
      <Link className="ant-breadcrumb-link" to={route.path}>
        {route.icon ?? false}
        {route.icon && " "}
        {route.breadcrumbName}
      </Link>
    );
  }

  return (
    <div style={{ paddingBottom: 10 }}>
      <Breadcrumb itemRender={itemRender} items={items} />
    </div>
  );
};

export default CustomBreadcrumb;
