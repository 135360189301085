import React, { useEffect, useState } from "react";
import { Card, Spin, Radio } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { HomeOutlined, SettingOutlined } from "@ant-design/icons";
import {
  updateSystemSettings,
  showSystemSettings,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import {
  SUBSCRIPTION_PLAN_DAILY,
  SUBSCRIPTION_PLAN_MONTHLY_YEARLY,
} from "../../../configs/constants";

const SystemSettings = () => {
  const [systemSettings, setSystemSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Set Loading True
    setLoading(true);
    showSystemSettings()
      .then((response) => {
        setSystemSettings(response.data.data.system_settings);
      })
      .catch((error) => {})
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  }, [dataLoading]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "system-settings",
      breadcrumbName: t("menu_system_settings"),
      icon: <SettingOutlined />,
    },
  ];

  const handleUpdate = (value) => {
    // Set Loading True
    setLoading(true);
    const formData = new FormData();
    if (value) {
      formData.append("subscription_scheme", value);
    }
    updateSystemSettings(formData)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setDataLoading(!dataLoading);
          // Success
          NotificationWithIcon(
            "success",
            t("settings_updated"),
            t("settings_updated_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data?.message
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_system_settings")}
          ghost={false}
        />
      </Card>
      <Spin spinning={loading}>
        <Card>
          <h4 style={{ marginTop: "0" }}> {t("col_subscription_plan")}</h4>
          <Radio.Group
            value={systemSettings.subscription_scheme}
            onChange={(e) => {
              handleUpdate(e.target.value);
            }}
          >
            <Radio
              value={SUBSCRIPTION_PLAN_DAILY}
              key={SUBSCRIPTION_PLAN_DAILY}
            >
              {t("daily")}
            </Radio>
            <Radio
              value={SUBSCRIPTION_PLAN_MONTHLY_YEARLY}
              key={SUBSCRIPTION_PLAN_MONTHLY_YEARLY}
            >
              {t("monthly_or_annually")}
            </Radio>
          </Radio.Group>
        </Card>
      </Spin>
    </>
  );
};

export default SystemSettings;
