import React from "react";
import { Tabs } from "antd";
import CompanyActivities from "../CompanyGeneralDetails/CompanyActivities";
import CompanyNotes from "../CompanyGeneralDetails/CompanyNotes";
import CompanyLogs from "../CompanyGeneralDetails/CompanyLogs";
import CompanyQuestionnaire from "../CompanyGeneralDetails/CompanyQuestionnaire";

const CompanyGeneralInfo = ({ company, reloadCallback }) => {
  const tabItems = [
    {
      label: "Logs",
      key: "tab-logs",
      children: <CompanyLogs />,
    },
    {
      label: "Activities",
      key: "tab-activities",
      children: <CompanyActivities />,
    },
    {
      label: "Questionnaire",
      key: "tab-questionnaire",
      children: <CompanyQuestionnaire />,
    },
    {
      label: "Notes",
      key: "tab-notes",
      children: <CompanyNotes />,
    },
  ];
  return (
    <>
      <Tabs defaultActiveKey="tab-logs" items={tabItems} />
    </>
  );
};

export default CompanyGeneralInfo;
