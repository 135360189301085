import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Spin,
  Empty,
  Popover,
  Pagination,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  DeleteTwoTone,
  EditFilled,
  MoreOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useParams } from "react-router-dom";
import {
  createNote,
  deleteNote,
  getNotes,
  updateNote,
} from "../../../network/network";
import moment from "moment";
import CommentsArea from "./CommentsArea";
import { AppContext } from "../../../context/AppContextProvider";

const CompanyNotes = () => {
  const { authUser } = useContext(AppContext);
  const ADD_NOTE = "Add Note";
  const [notesData, setNotesData] = useState([]);
  const [reloadNotesData, setReloadNotesData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [noteForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const { t } = useTranslation();
  let { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    getNotes(companyId, paginationPage)
      .then((response) => {
        setNotesData(response.data.data.notes);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadNotesData, companyId, paginationPage]);

  const handlePagination = (page, pageSize) => {
    setPaginationPage(page);
  };

  const content = (notesId) => (
    <>
      <Button
        style={{
          border: 0,
          boxShadow: 0,
          display: "block",
          marginBottom: "5px",
        }}
        icon={<EditFilled title={t("edit")} />}
        onClick={() => handleEditModal(notesId)}
      >
        {t("edit")}
      </Button>
      <Popconfirm
        title={t("warning_delete")}
        onConfirm={() => handleNoteDelete(notesId)}
        okText={t("yes")}
        cancelText={t("no")}
      >
        <Button
          style={{ border: 0, boxShadow: 0, display: "block" }}
          icon={
            <DeleteTwoTone twoToneColor="#ff0000" title={t("delete_entity")} />
          }
        >
          {t("delete")}
        </Button>
      </Popconfirm>
    </>
  );

  const showModal = () => {
    // Show note Modal
    setModalVisible(true);
    // Set add note Modal Title
    setModalTitle(t("note_add"));
    // Reset note Form Fields
    noteForm.resetFields();
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadNotesData(!reloadNotesData);
      // Success
      if (type === ADD_NOTE) {
        NotificationWithIcon(
          "success",
          t("note_added"),
          response.data.message
          // t("note_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("note_updated"),
          response.data.message
          // t("note_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    noteForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    noteForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_NOTE || modalTitle === t("note_add")) {
          createNote(companyId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, ADD_NOTE);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateNote(companyId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (id) => {
    // Find note from DataArray
    const note = notesData.find((note) => note.id === id);
    // Set Modal Title
    setModalTitle(t("note_edit"));
    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    noteForm.setFieldsValue({
      id: note.id,
      description: note.description,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    noteForm.resetFields();
    // close modal
    setModalVisible(false);
  };

  const handleNoteDelete = (id) => {
    deleteNote(companyId, id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadNotesData(!reloadNotesData);
        // Success
        NotificationWithIcon(
          "success",
          t("note_deleted"),
          data.message
          // t("note_deleted_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const reloadData = useCallback(() => {
    setReloadNotesData(!reloadNotesData);
  }, [reloadNotesData]);

  return (
    <>
      <Spin size="large" spinning={loading}>
        <div style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            <PlusOutlined title={t("note_add")} /> {t("note_add")}
          </Button>
        </div>
        {notesData.length > 0 ? (
          <>
            {notesData.map((note) => (
              <div
                className="notes_card"
                style={{
                  boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                  margin: "20px",
                  borderRadius: "10px",
                }}
                key={note.id}
              >
                <div
                  className="notes_head"
                  style={{
                    padding: "20px",
                    borderBottom: "1px solid #e2e8f0",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <UserOutlined style={{ fontSize: "18px" }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexGrow: "1",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {note.created_by.name}
                        </span>
                        left a note on
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        >
                          {moment(note.created_at).format("MMM. D, YYYY HH:mm")}
                        </span>
                      </div>
                    </div>
                    {authUser.id === note.created_by.id && (
                      <div style={{ marginLeft: "10px" }}>
                        <Popover
                          placement="left"
                          content={content(note.id)}
                          trigger="hover"
                        >
                          <MoreOutlined
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          />
                        </Popover>
                      </div>
                    )}
                  </div>
                </div>
                <div className="notes_body" style={{ padding: "20px" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      {note.description}
                    </div>
                  </div>
                </div>
                <div
                  className="note_footer"
                  style={{
                    padding: "20px",
                    backgroundColor: "#f8fafc",
                    borderRadius: "0 0 10px 10px",
                  }}
                >
                  <CommentsArea
                    data={note}
                    type="note"
                    reloadData={reloadData}
                  />
                </div>
              </div>
            ))}
            {paginationData.current_page &&
              paginationData.count > 0 &&
              paginationData.total > paginationData.per_page && (
                <Pagination
                  current={paginationData.current_page}
                  total={paginationData.total}
                  pageSize={paginationData.per_page}
                  onChange={handlePagination}
                  showSizeChanger={false}
                  defaultCurrent={paginationData.current_page}
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                />
              )}
          </>
        ) : (
          <Empty />
        )}
        {/* add or edit note */}
        <Modal
          open={modalVisible}
          title={modalTitle}
          onOk={handleAddEdit}
          onCancel={handleCancel}
          footer={[
            <Button key="back" loading={loading} onClick={handleCancel}>
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddEdit}
            >
              {modalTitle === ADD_NOTE || modalTitle === t("note_add")
                ? t("note_add")
                : t("update")}
            </Button>,
          ]}
        >
          <Form
            name="note-form"
            layout="vertical"
            onFinish={handleAddEdit}
            style={{ width: "100%", margin: "0" }}
            form={noteForm}
          >
            <Form.Item
              name="description"
              label={t("description")}
              rules={[
                { required: true, message: t("validate_description_required") },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item name="id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

export default CompanyNotes;
