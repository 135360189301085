import React, { useContext, useEffect, useState } from "react";
import { Button, Popover, Table, Tag, Form, Select, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { getIssueTypes, updateTickets } from "../../../network/network";
import { EyeOutlined, MoreOutlined, ToolOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContextProvider";

const TicketsTable = ({
  tickets,
  currentTab,
  reloadCallback,
  loading,
  paginationData,
}) => {
  const { setActiveMenu } = useContext(AppContext);
  const [issueTypes, setIssueTypes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { t } = useTranslation();
  const [issueTypeForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getIssueTypes().then((response) => {
      setIssueTypes(response.data.data.issue_types);
    });
  }, []);

  const showAddModal = (id) => {
    // Reset Form Fields
    issueTypeForm.resetFields();
    // Set Form Fields
    issueTypeForm.setFieldsValue({
      id: id,
    });
    // Show Modal
    setModalVisible(true);
  };

  const handleAssignIssueType = () => {
    issueTypeForm
      .validateFields()

      .then((values) => {
        // Set Loading True
        setDataLoading(true);
        // Sending Request to API
        updateTickets(values.id, values)
          .then((response) => {
            if (response.data.success === true) {
              // Reload Callback
              reloadCallback();
              // Success
              NotificationWithIcon(
                "success",
                t("status_updated_successfully"),
                t("helpdesk_ticket_status_updated_successfully")
              );
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                response.data.message
              );
            }
          })
          .finally(() => {
            // Set Loading False
            setDataLoading(false);
            // Hide Modal
            setModalVisible(false);
          });
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    issueTypeForm.resetFields();
    setModalVisible(false);
  };

  const TicketsTableColumns = [
    {
      title: "#",
      align: "center",
      width: "50px",
      key: "index",
      render: (text, record, index) => {
        return (
          <p>
            {paginationData.current_page
              ? paginationData.per_page * (paginationData.current_page - 1) +
                (index + 1)
              : index + 1}
          </p>
        );
      },
    },
    {
      title: t("col_description"),
      dataIndex: "description",
    },
    {
      title: t("col_customer_name"),
      dataIndex: "created_by",
      width: "140px",
      render: (created_by) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveMenu("companies");
                navigate({
                  pathname: "/companies/" + created_by.company_id,
                });
              }}
            >
              {created_by.name}
            </span>
          </>
        );
      },
    },
    {
      title: t("col_csr_name"),
      dataIndex: "support_agent",
      width: "140px",
      render: (support_agent) => {
        return <>{support_agent.name}</>;
      },
    },
    {
      title: t("col_attachment"),
      dataIndex: "attachment",
      width: "150px",
      render: (attachment) => {
        return (
          <>
            <div>
              {attachment ? (
                <Button
                  type="primary"
                  href={attachment}
                  size="small"
                  target="_blank"
                >
                  <EyeOutlined /> {t("preview")}
                </Button>
              ) : (
                <div>{t("no_attachment")}</div>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: t("col_contact"),
      dataIndex: "created_by",
      width: "120px",
      render: (created_by) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveMenu("companies");
                navigate({
                  pathname: "/companies/" + created_by.company_id,
                });
              }}
            >
              {created_by.phone}
            </span>
          </>
        );
      },
    },
    {
      title: t("col_action"),
      width: "120px",
      align: "center",
      render: (ticket) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => showAddModal(ticket.id)}
            disabled={ticket.issue_type}
          >
            {t("mark_as_issue")}
          </Button>
        );
      },
    },
    {
      title: <ToolOutlined />,
      width: "40px",
      align: "center",
      render: (ticket) => {
        const content = (
          <>
            {currentTab !== "new_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 1)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="cyan"
              >
                Mark as New
              </Tag>
            )}
            {currentTab !== "inprogress_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 2)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="green"
              >
                Mark as in Progress
              </Tag>
            )}
            {currentTab !== "done_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 3)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="blue"
              >
                Mark as Done
              </Tag>
            )}
            {currentTab !== "closed_tickets" && (
              <Tag
                onClick={() => handleUpdateTicketStatus(ticket.id, 4)}
                style={{ cursor: "pointer", margin: "5px" }}
                color="purple"
              >
                Mark as Closed
              </Tag>
            )}
          </>
        );
        return (
          <Popover placement="left" content={content} trigger="hover">
            <MoreOutlined />
          </Popover>
        );
      },
    },
  ];

  const handleUpdateTicketStatus = (id, status) => {
    const formData = new FormData();
    if (status) {
      formData.append("status", status);
    }
    updateTickets(id, formData)
      .then((response) => {
        if (response.data.success === true) {
          // Reload Callback
          reloadCallback();
          // Success
          NotificationWithIcon(
            "success",
            t("ticket_updated"),
            t("ticket_updated_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      });
  };

  return (
    <>
      <Table
        columns={TicketsTableColumns}
        dataSource={tickets}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
      <Modal
        open={modalVisible}
        title={t("add_deatils")}
        onOk={handleAssignIssueType}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={dataLoading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={dataLoading}
            onClick={handleAssignIssueType}
          >
            {t("add")}
          </Button>,
        ]}
      >
        <Form
          name="issue-Type-form"
          layout="vertical"
          onFinish={handleAssignIssueType}
          style={{ width: "100%", margin: "0" }}
          form={issueTypeForm}
        >
          <Form.Item
            name="issue_comment"
            label={t("comment")}
            rules={[
              {
                required: true,
                message: t("validate_comment_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="issue_type_id"
            label={t("menu_issue_types")}
            rules={[
              {
                required: true,
                message: t("validate_issue_types_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              allowClear
              options={issueTypes.map((issueType) => {
                return {
                  label: issueType.type,
                  value: issueType.id,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TicketsTable;
