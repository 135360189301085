import React, { useEffect, useState } from "react";
import { Empty, Pagination, Spin, Timeline } from "antd";

import {
  // AlignLeftOutlined,
  // BellOutlined,
  // CalendarOutlined,
  // CheckCircleTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import { getLogs } from "../../../network/network";
import { useParams } from "react-router-dom";
import moment from "moment";

const CompanyLogs = () => {
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  let { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    getLogs(companyId, paginationPage)
      .then((response) => {
        setLogsData(response.data.data.logs);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId, paginationPage]);

  const handlePagination = (page, pageSize) => {
    setPaginationPage(page);
  };

const timelineItems = logsData.length > 0 ? logsData.map((log) => ({
  children: (
    <div
      className="log_card"
      style={{
        boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
        margin: "0 10px 0 0",
        borderRadius: "10px",
      }}
    >
      <div
        className="log_head"
        style={{
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexGrow: "1",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              {log.created_by && (
                <UserOutlined style={{ fontSize: "18px" }} />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexGrow: "1",
              }}
            >
              {log.created_by && (
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  {log.created_by.name}
                </span>
              )}
              {log.action} -
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                {moment(log.created_at).format(
                  "MMM. D, YYYY HH:mm"
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ),
  key: log.id,
})) : [{
  children: <Empty />,
  key: 'empty',
}];

  return (
    <>
      <Spin size="large" spinning={loading}>
        <div style={{ marginTop: "20px" }}>
              <Timeline className="log_timeLine" items={timelineItems} />

          {/* <Timeline className="log_timeLine">
            {logsData.length > 0 ? (
              logsData.map((log) => (
                <Timeline.Item key={log.id}>
                  <div
                    className="log_card"
                    style={{
                      boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                      margin: "0 10px 0 0",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      className="log_head"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: "1",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            {log.created_by && (
                              <UserOutlined style={{ fontSize: "18px" }} />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexGrow: "1",
                            }}
                          >
                            {log.created_by && (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "5px",
                                }}
                              >
                                {log.created_by.name}
                              </span>
                            )}
                            {log.action} -
                            <span
                              style={{
                                fontWeight: "bold",
                                marginLeft: "5px",
                              }}
                            >
                              {moment(log.created_at).format(
                                "MMM. D, YYYY HH:mm"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Timeline.Item>
              ))
            ) : (
              <Empty />
            )}
          </Timeline> */}
          {paginationData.current_page &&
            paginationData.count > 0 &&
            paginationData.total > paginationData.per_page && (
              <Pagination
                current={paginationData.current_page}
                total={paginationData.total}
                pageSize={paginationData.per_page}
                onChange={handlePagination}
                showSizeChanger={false}
                defaultCurrent={paginationData.current_page}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              />
            )}
        </div>
      </Spin>
    </>
  );
};

export default CompanyLogs;
