import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown } from "antd";
import {
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../network/network";
import NotificationWithIcon from "./NotificationWithIcon";
import { useState } from "react";

const NotificationsDropdown = ({
  unReadNotificationsCount,
  notifications,
  changeUnreadNotificationsCount,
  onLoadMore,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownVisible, setDropdownVisible] = useState(false); // Add this state

  const handleLoadMore = (event) => {
    event.stopPropagation();
    onLoadMore(currentPage + 1);
    setCurrentPage((prevPage) => prevPage + 1);
    // setDropdownVisible(true);
  };

  const redirectToUrl = (notification) => {
    let subUrl = "";
    if (
      notification.notification === "HelpdeskTicketCreated" ||
      notification.notification === "HelpdeskTicketLate"
    ) {
      subUrl = "/helpdesk?status=new_tickets";
    } else if (notification.notification === "HelpdeskTicketDelayed") {
      subUrl = "/helpdesk?status=inprogress_tickets";
    } else if (
      notification.notification === "CompanyCreated" ||
      notification.notification === "CompanyAdminStaffChanged"
    ) {
      subUrl = "/companies/" + notification.company_id;
    }

    window.location.href = window.location.origin + subUrl;
  };

  const handleMarkAsRead = async (notification) => {
    let isRead = notification.read_at !== null;

    markNotificationAsRead(notification.id)
      .then((response) => {
        if (!isRead) {
          changeUnreadNotificationsCount(unReadNotificationsCount - 1);
        }
        redirectToUrl(notification);
      })
      .catch((info) => {
        // Error
        console.log("Notification mark as read error: ", info.response.message);
        redirectToUrl(notification);
      });
  };

  const handleMarkAllAsRead = async () => {
    markAllNotificationsAsRead()
      .then((response) => {
        changeUnreadNotificationsCount(0);
        NotificationWithIcon(
          "success",
          "Mark notifications as read",
          "All notifications have been marked as read successfully."
        );
      })
      .catch((info) => {
        NotificationWithIcon(
          "error",
          "Mark notification as read Error",
          "There is some error in marking all notifications as read."
        );
      });
  };

  const menuItems = notifications.map((notification, index) => {
    const menuItemStyle = {
      color: notification.read_at ? "gray" : "inherit", // Change color to gray if read_at is not null
    };

    return {
      key: index,
      label: (
        <Button
          type="text"
          style={menuItemStyle}
          onClick={() => handleMarkAsRead(notification)}
        >
          {notification.message}
        </Button>
      ),
    };
  });

  return (
    <Dropdown
      menu={{
        items: [
          ...menuItems,
          {
            key: "load-more",
            label: (
              <Button
                type="link"
                onClick={(event) => handleLoadMore(event)}
                className="load-more-button"
              >
                Load More
              </Button>
            ),
          },
          {
            type: "divider",
          },
          {
            key: "mark-all-as-read",
            label: (
              <Button type="link" onClick={handleMarkAllAsRead}>
                Mark all as Read
              </Button>
            ),
          },
        ],
      }}
      trigger={["hover"]}
      open={dropdownVisible}
      onOpenChange={setDropdownVisible}
    >
      <Badge count={unReadNotificationsCount}>
        <BellOutlined style={{ fontSize: "24px", color: "#08c" }} />
      </Badge>
    </Dropdown>
  );
};

export default NotificationsDropdown;
