import { Row, Col, Form, Input, message, Button } from "antd";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import { userLogin } from "../../../network/network";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  USER_TYPE_MANAGER,
  USER_TYPE_ADMIN_STAFF,
  USER_TYPE_SUPER_ADMIN,
} from "../../../configs/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Login = () => {
  const { setAuthUser, setFullPageLoading } = useContext(AppContext);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const setPuserAuth = (authUser) => {
    window.Echo.connector.options.auth.headers["Authorization"] =
      "Bearer " + authUser.access_token;
    window.Echo.connector.options.auth.headers["Accept"] = "application/json";
  };

  const onFinish = (values) => {
    setFullPageLoading(true);
    userLogin(values)
      .then((response) => {
        if (response.data && response.data.success === true) {
          if (
            response.data.data.user.type === USER_TYPE_MANAGER ||
            response.data.data.user.type === USER_TYPE_ADMIN_STAFF ||
            response.data.data.user.type === USER_TYPE_SUPER_ADMIN
          ) {
            localStorage.setItem(
              "access_token",
              response.data.data.user.access_token
            );
            setPuserAuth(response.data.data.user);
            setAuthUser(response.data.data.user);
            navigate("/home");
          } else {
            message.error("Invalid E-mail / Password Combination.");
          }
        } else {
          message.error(response.response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          message.error(error.response.data.message);
        } else if (error.response.status === 500) {
          let errorMessages = Object.values(error.response.data.errors);
          errorMessages.forEach(function (key, value) {
            let temp = Object.values(key);
            message.error(temp[0]);
          });
        }
      })
      .finally(() => {
        setFullPageLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <>
      <Row type="flex" align="middle" justify="center">
        <Col
          span={12}
          style={{
            background: "#ffffff",
            height: "100vh",
            padding: "10% 10%",
          }}
        >
          <Row type="flex" align="middle" justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <img src="/logo.png" alt="AnyPOS Logo" />
              <Form
                name="login-user-form"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ width: "300px", margin: "20px auto 0" }}
              >
                <Form.Item
                  name="phone"
                  label={t("phone_number") + " eg: +966 5xxxxxxx"}
                  rules={[
                    {
                      required: true,
                      message: t("validate_phone_required"),
                    },
                    {
                      min: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                    {
                      max: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                  ]}
                  style={{ textAlign: "left" }}
                  hasFeedback
                >
                  <PhoneInput
                    localization={["sa"]}
                    masks={{ sa: "(...) ..-..-.." }}
                    placeholder="+966 (123) 45-67-89"
                    country={"sa"}
                    onlyCountries={["sa"]}
                    inputStyle={{
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={t("password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button size="large" block type="primary" htmlType="submit">
                    {t("login")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
