import {
  deleteSubscription,
  ExtendToYearlyTrial,
  generateInvoice,
  SubscribeYearlyTrial,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { Button, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../utils";
import { PLAN_TYPE_PRO, PERIOD_YEARLY } from "../../../configs/constants";
import moment from "moment";
import { useState } from "react";

const CompanySubscriptions = ({
  subscriptions,
  company,
  reloadCallback,
  isDisabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  // const [reload, setReload] = useState(false);
  // const [reloadTableData, setReloadTableData] = useState(false);
  // const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const tableColumnsData = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("col_subscription_plan"),
      render: (subscription) => {
        return (
          <>
            {subscription.type === PLAN_TYPE_PRO ? (
              <Tag color="green">{subscription.name}</Tag>
            ) : (
              <Tag color="blue">{subscription.name}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("col_price"),
      render: (subscription) => {
        return (
          <>
            {currencyFormatter.format(
              subscription.amount +
                (subscription.license_amount - subscription.license_discount) *
                  subscription.user_licenses_count_all
            )}{" "}
            / {subscription.period === PERIOD_YEARLY ? t("year") : t("month")}
            {subscription.is_trial === true && subscription.start_date && (
              <>
                <br />
                <small>
                  * Pay on{" "}
                  {moment(subscription.start_date).format("MMM. D, YYYY")}{" "}
                </small>
              </>
            )}
          </>
        );
      },
    },
    {
      title: t("no_of_licenses"),
      align: "center",
      render: (subscription) => {
        return subscription.user_licenses_count_all;
      },
    },
    {
      title: t("col_start_date"),
      dataIndex: "start_date",
      render: (start_date) => {
        return start_date ? moment(start_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_end_date"),
      dataIndex: "end_date",
      render: (end_date) => {
        return end_date ? moment(end_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("status"),
      render: (subscription) => {
        return (
          <>
            {subscription.status ? (
              <Tag color="green">{t("active")}</Tag>
            ) : (
              <Tag color="blue">{t("requested")}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (subscription) => {
        return (
          <>
            {subscription.is_trial === 1 && <Tag>Trial</Tag>}
            {!subscription.status && (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    handleAcceptSubscriptionRequest(subscription.id)
                  }
                  disabled={isDisabled}
                >
                  Approve
                </Button>
                &nbsp;
                <Button
                  type="danger"
                  size="small"
                  onClick={() =>
                    handleDeleteSubscriptionRequest(subscription.id)
                  }
                  disabled={isDisabled}
                >
                  Decline
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleAcceptSubscriptionRequest = (subscription_id) => {
    generateInvoice(subscription_id).then((response) => {
      if (response.data.success === true) {
        NotificationWithIcon(
          "success",
          t("subscription_request_accepted"),
          t("subscription_request_accepted_successfully")
        );
        // Reload Callback
        reloadCallback();
      }
    });
  };

  const handleDeleteSubscriptionRequest = (subscription_id) => {
    deleteSubscription(subscription_id).then((response) => {
      if (response.data.success === true) {
        NotificationWithIcon(
          "success",
          t("subscription_request_deleted"),
          t("subscription_request_deleted_successfully")
        );
        // Reload Callback
        reloadCallback();
      }
    });
  };

  const handleSubscribeYearlyTrialRequest = (company_id) => {
    // Set Loading true
    setLoading(true);
    SubscribeYearlyTrial(company_id)
      .then((response) => {
        if (response.data.success === true) {
          NotificationWithIcon(
            "success",
            t("subscription_request_accepted"),
            t("subscription_request_accepted_successfully")
          );
          // Reload Callback
          reloadCallback();
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const handleExtendToYearlyTrialRequest = (company_id) => {
    // Set Loading true
    setLoading(true);
    ExtendToYearlyTrial(company_id)
      .then((response) => {
        if (response.data.success === true) {
          NotificationWithIcon(
            "success",
            t("subscription_request_accepted"),
            t("subscription_request_accepted_successfully")
          );
          // Reload Callback
          reloadCallback();
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        {company.active_subscription &&
          company.active_subscription.validity_days === 90 &&
          company.active_subscription.is_trial === 1 && (
            <Button
              type="button"
              onClick={() => {
                handleExtendToYearlyTrialRequest(company.id);
              }}
              style={{
                marginRight: "20px",
                backgroundColor: "green",
                color: "white",
              }}
              disabled={isDisabled}
            >
              {t("extend_to_yearly_trial")}
            </Button>
          )}

        {!company.yearly_trial_availed && (
          <Button
            type="primary"
            onClick={() => {
              handleSubscribeYearlyTrialRequest(company.id);
            }}
            disabled={isDisabled}
          >
            {t("subscribe_to_yearly_trial")}
          </Button>
        )}
      </div>

      <Table
        columns={tableColumnsData}
        dataSource={subscriptions}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default CompanySubscriptions;
